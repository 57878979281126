import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import HalfHero from '../components/half-hero'
import RegCircles from '../components/reg-circles'

const Page = () => {
  const data = useStaticQuery(graphql`
    query TC {
      tc: markdownRemark(fields: { parent: { relativeDirectory: { eq: ""}, name: {eq: "terms-and-conditions"}}}) {
        html
        frontmatter {
          title
          link
          step
          agreeText
          button
        }
      }
    }
  `)

  const { tc } = data

  return (
    <>
      <SEO title="Registration" />

      <HalfHero fullWidth />

      <section style={{marginTop: '2rem'}}>

        <h1>{tc.frontmatter.title}</h1>

        <RegCircles active="1" />

        <div 
          dangerouslySetInnerHTML={{ __html: tc.html }}
        />

        <div className="text-center">
          <div>{tc.frontmatter.step}</div>

          <div><Link to="/EULA/">{tc.frontmatter.link}</Link></div>

          <div className="agreement">
            <input type="checkbox" id="checkBox" name="checkBox" />
            <label htmlFor="checkBox"><strong>{tc.frontmatter.agreeText}</strong></label>
          </div>

          <div>
            <Link to="/Registration"><button type="submit">{tc.frontmatter.button}</button></Link>
          </div>
        </div>


      </section>
    </>
  )
}

export default Page