import React from 'react'

const RegCircles = ({active}) => {
  return (
    <div className="reg-circles">
      <div className={active === "1"? 'reg-circles__item active' : 'reg-circles__item'}><span>1</span></div>
      <div className={active === "2"? 'reg-circles__item active' : 'reg-circles__item'}><span>2</span></div>
      <div className={active === "3"? 'reg-circles__item active' : 'reg-circles__item'}><span>3</span></div>
    </div>
  )
}

export default RegCircles
